import React, { useState, useContext, useEffect, useRef, Suspense } from "react";
import { useTranslation } from 'react-i18next';
import GridTable from '@nadavshaar/react-grid-table';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { client } from 'websocket';
import { masterUser, getJSONParse } from "../../components/tools";
import { parseISO, format, formatISO, subMonths, isToday, formatDistanceStrict, differenceInDays } from 'date-fns'
import Modal from 'react-bootstrap/Modal';
import { Form, Button, FloatingLabel, InputGroup, Spinner, Table, Dropdown, DropdownButton } from "react-bootstrap";
import { ToastContainer, toast, Slide } from 'react-toastify';

import { UserContext, isBrand, useWindowSize } from "../../components";
import { stamp } from "../../components/DDD/DDDViewColumns"
import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { statusstamp, statusstampSort, addressstamp } from '../../components/DevicesView/columnsDevicesView'
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineSearch } from 'react-icons/ai'
import { IoMdArrowRoundBack } from "react-icons/io";
import Loading from "../../components/Loading";
import { FaFilter, FaFilterCircleXmark } from "react-icons/fa6";
import { PiWarning } from 'react-icons/pi';
import { BsCheck2Circle } from 'react-icons/bs'

const Excel = React.lazy(() => import('../../components/export').then(module => ({ default: module.Excel })));

export default function DDDcheck() {
    const navigate = useNavigate();
    document.title = 'DDD - kontrola stahování'

    const [list, setList] = useState(false);
    const [loading, setLoading] = useState(true);
    const [row, setRow] = useState(null);
    const [filter, setFilter] = useState({ checkedHide: true });
    const refWS29 = useRef(null);
    const height = useWindowSize().height;

    const { clientRoles, costumer, user } = useContext(UserContext);

    const [isAscending, seIsAscending] = useState(
        { colId: 6, isAsc: true }
    );

    useEffect(() => {
        if (masterUser.includes(user.uname) && clientRoles.some(i => i.type.symbol === 'data_curator')) {
            if (!list && !refWS29.current) {
                refWS29.current = true;
                WsSend({
                    method: 'getExportNode',
                    oid: 29,
                    type: 'DDDcheck'
                });
                const handleMessage = (message) => {
                    let data = WsAnal(message)
                    if (data && data.oid === 29) {
                        console.log(data)
                        setList(WSdata(data, null, 29))
                        setLoading(false)
                        client.removeEventListener('message', handleMessage);
                    }
                }
                client.addEventListener('message', handleMessage, { once: true });
            }
        }
        else navigate('/')
    }, [list])

    const columns = [
        {
            id: 1,
            index: 0,
            field: 'clientname',
            width: 'auto',
            label: 'Zákazník',
            visible: true,
            sortable: true,
        },
        {
            id: 2,
            index: 1,
            field: 'server',
            width: 'auto',
            label: 'server',
            visible: true,
            sortable: true,
        },
        {
            id: 3,
            index: 2,
            field: 'plate',
            label: 'SPZ',
            visible: true,
            sortable: true,
        },
        {
            id: 4,
            index: 3,
            field: 'deviceName',
            label: 'Název',
            visible: true,
            sortable: true,
        },
        {
            id: 5,
            index: 4,
            field: 'ridestamp',
            width: 'auto',
            label: 'Poslední jízda',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: stamp
        },
        {
            id: 6,
            index: 5,
            field: 'lastFile',
            label: 'Poslední soubor',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value, data }) => {
                if (value === undefined || value.length === 0 || typeof value !== 'string') return (<></>)
                const stamp = parseISO(value);
                const passDay = differenceInDays(new Date(), stamp);
                let icon = <PiWarning className='me-1 fs-5 text-danger' />;
                if (passDay < 89) icon = <BsCheck2Circle className='me-1 fs-5 text-success' />;
                return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <span className='rgt-text-truncate fs-7' title={format(stamp, 'dd.MM.yyyy HH:mm')}>
                            {icon}
                            {formatDistanceStrict(stamp, new Date(), { addSuffix: true, unit: isToday(stamp) ? undefined : 'day' })}
                        </span>
                    </div>
                )
            },
            sort: statusstampSort,
        },
        {
            id: 7,
            index: 6,
            field: 'lastcheck',
            width: 'auto',
            label: 'Kontrola',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: stamp,
            sort: statusstampSort,
        },
    ]

    const getRows = ((r) => {
        if (!r) return null
        let res = r.map((d, index) => {
            return ({
                'id': d.id.toString(),
                'server': d.server,
                'clientname': `[${d.client}] ${d.clientname}`,
                'deviceName': d.name,
                'device': d.id,
                'plate': d.plate,
                'ridestamp': d.ridestamp,
                'lastFile': d.stamp,
                'text': d.text,
                'lastcheck': d.lastcheck,
                'dbuser': d.dbuser
            })
        })
        if (filter.checkedHide) res = res.filter(c => c.lastcheck ? false : true)

        return res
    })

    const setChecked = (c) => {
        WsSend({
            method: 'getExportNode',
            oid: 29,
            type: 'DDDsetChecked',
            device: user.id,
            stampTo: c.id,
            stampFrom: c.server
        });
        setLoading(true)
        const handleMessage = (message) => {
            let data = WsAnal(message)
            if (data && data.oid === 29) {
                client.removeEventListener('message', handleMessage);
                if (data.value === 1) {
                    setRow(false)
                    refWS29.current = false;
                    setList(false)
                    setTimeout(() => {
                        toast.success('Úspešně uloženo')
                    }, 100);

                }
                else navigate('/error')
            }
        }
        client.addEventListener('message', handleMessage, { once: true });

    }

    if (!loading) return (
        <div className="config" style={{ minHeight: '100vh' }}>
            <div className='gridHandle d-flex justify-conten-evenly'>
                <div className="back">
                    <Link to="/admin">
                        <IoMdArrowRoundBack className="fs-5 mx-2 text-secondary my-1 ms-2" />
                    </Link>
                </div>
                <span className={`ms-2 gridHandleOn`}>DDD - kontrola stahování</span>
                <span >
                    <Dropdown className="ms-3">
                        <Dropdown.Toggle variant="light" className="p-0 px-2">
                            {filter.checkedHide ? <FaFilterCircleXmark /> : <FaFilter />}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="me-2 bg-light">
                            <div className="px-2 pt-3">
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check role="button" type="checkbox" label="Skrýt zkontrolované" defaultChecked={filter.checkedHide} onChange={(e) => setFilter({ ...filter, checkedHide: e.target.checked })} />
                                </Form.Group>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>

                </span>
                <span className="m-auto">  </span>
                <Suspense >
                    <span style={{ position: 'fixed', right: '15%', paddingRight: '70px' }}>
                        <Excel
                            excelData={getRows(list)}
                            fileName={'DDDcheck'}
                            clientName={costumer}
                            exportCsv={true}
                            classStyle={'mx-1 p-0 px-1'} />
                        <Excel
                            excelData={getRows(list)}
                            fileName={'DDDcheck'}
                            clientName={costumer}
                            exportCsv={false}
                            classStyle={'mx-1 p-0 px-1'} />
                    </span>
                </Suspense >
            </div>
            <div className="d-flex justify-content-center">
                <GridTable
                    style={{ minHeight: 'unset', height: (height - 30) + 'px' }}
                    className={'config'}
                    columns={columns}
                    rows={getRows(list)}
                    showSearch={true}
                    showColumnVisibilityManager={false}
                    showRowsInformation={true}
                    isPaginated={false}
                    texts={{
                        search: '',
                        noResults: list && list.length > 0 ? 'žádné data' : '',
                        totalRows: 'Celkem:',
                    }}
                    icons={{ search: <AiOutlineSearch /> }}
                    highlightSearch={true}
                    onSortChange={({ colId, isAsc }) => {
                        seIsAscending({ colId: colId, isAsc: isAsc })
                    }}
                    sort={isAscending}
                    selectedRowsIds={[row ? row.id : '']}
                    onRowClick={({ data }) => {
                        setRow(data)
                    }}
                />
            </div>
            {row ? (
                <Modal size='xl' fullscreen='sm-down' centered show={row} onHide={() => { setRow(false) }} >
                    <Modal.Header closeButton>
                        <Modal.Title>{`${row.server} ${row.clientname} - ${row.deviceName} [${row.plate}]`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {row.text.split('\r\n').map((paragraph, index) => (
                                <p className="m-0 p-0" key={index}>{paragraph}</p>
                            ))}
                        </div>
                        {!row.lastcheck ? (<>
                            <div className="text-center mt-3"><Button disabled={!row.lastFile} onClick={() => { setChecked(row) }}>Nastavit zkontrolování</Button>
                                {!row.lastFile ? <div>Nastavit zkontrolování není možné, vymaž ddd z konfigurace.</div> : ''}
                            </div>
                        </>) : (<div className="text-center"><b>Zkontrolováno dne {format(parseISO(row.lastcheck), 'dd.MM.yyyy HH:mm')} {row.dbuser}</b></div>)}

                    </Modal.Body>
                </Modal>) : ''}
            <ToastContainer
                position="bottom-center"
                autoClose={1500}
                pauseOnHover={false}
                theme="light"
                transition={Slide}
                closeOnClick
                hideProgressBar
            />
        </div>

    )
    else return (
        <>
            <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} />
            <ToastContainer
                position="bottom-center"
                autoClose={1500}
                pauseOnHover={false}
                theme="light"
                transition={Slide}
                closeOnClick
                hideProgressBar
            />
        </>
    )
}